/*-----------------------------------------------
|   Position
-----------------------------------------------*/
@each $breakpoint in map_keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $position in $positions {
      .position#{$infix}-#{$position} {
        position: $position !important;
      }
    }
  }
}

/*-----------------------------------------------
|   For Stickyfill on IE
-----------------------------------------------*/
.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0
}

/*-----------------------------------------------
|   Position
-----------------------------------------------*/
.container, .container-fluid { position: relative; }

.t-0 { top: 0; }
.b-0 { bottom: 0; }
.l-0 { left: 0; }
.r-0 { right: 0; }
.a-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.absolute-centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
