/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::selection {
  color: $white;
  background-color: map_get($theme-colors, danger);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

section {
  position: relative;
  padding-top: map_get($spacers, 6);
  padding-bottom: map_get($spacers, 6);
  @include media-breakpoint-up(lg){
    padding-top: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 8);
  }
}
