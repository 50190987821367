.hoverdir-item-content {
	position: absolute;
	background: rgba(0,0,0,.9);
	width: 100%;
	height: 100%;
	top:0;
	display: none;
	border-radius: $border-radius;
}
.hoverdir-text {
    opacity: 0;
    transition: $transition-base;
}
.hoverdir-item-content[style*="left: 0px; top: 0px;"] {
    .hoverdir-text {
        opacity: 1;
    }
}