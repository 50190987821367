//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/
$white: #fff !default;
$gray-100: #f5f5f5 !default;
$gray-200: #ecedee !default;
$gray-300: #dadcdf !default;
$gray-400: #c8cbcf !default;
$gray-500: #b6babf !default;
$gray-600: #9ba0a7 !default;
$gray-700: #777e87 !default;
$gray-800: #5f656c !default;
$gray-900: #4f545a !default;
$gray-1000: #373b3f !default;
$gray-1100: #17191b !default;
$black: #000 !default;


$grays: () !default;
$grays: map_merge($grays, (
  'black': $black,
  // 'gray': $gray,
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
  '1000': $gray-1000,
  '1100': $gray-1100,
  'white': $white,
));

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map_merge($theme-colors, (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
));

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 167 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $black !default;


//*-----------------------------------------------
//|   Social colors
//-----------------------------------------------*/
$linkedin: #0077B5 !default;
$facebook: #3b5998 !default;
$twitter: #1da1f2 !default;
$google-plus: #db4437 !default;
$github: #24292e !default;
$youtube: #ff0002 !default;


//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/
$box-shadow-lg: 0 1rem 4rem rgba($black, .175) !default;;

//*-----------------------------------------------
//|   Spacing
//-----------------------------------------------*/

$spacer: 1.25rem !default;
$spacers: () !default;
$spacers: map-merge($spacers, (
  0 : 0,
  1 : ($spacer * 0.25),
  2 : ($spacer * 0.5),
  3 : ($spacer * 1),
  4 : ($spacer * 2),
  5 : ($spacer * 3),
  6 : ($spacer * 4),
  7 : ($spacer * 5),
  8 : ($spacer * 6),
  9 : ($spacer * 7),
  10 : ($spacer * 8),
  11 : ($spacer * 9),
));



//*-----------------------------------------------
//|   Grid
//-----------------------------------------------*/

$grid-gutter-width: 40px !default;



//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;
$border-radius-soft: 0.625rem !default;
$border-radius-capsule: 3.125rem !default;
$border-color: $gray-300 !default;

//*-----------------------------------------------
//|   Transition
//-----------------------------------------------*/

$transition-base: all .4s ease-in-out !default;



//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/

$font-family-sans-serif: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif: Trirong, Times, 'Times New Roman', Georgia, serif !default;
$font-family-base: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;



//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/

$font-sizes: () !default;
$font-sizes: map-merge( $font-sizes, (
  '-1': 0.728875rem,
  0: 0.875rem,
  1: 1.2rem,
  2: 1.44rem,
  3: 1.728rem,
  4: 2rem,
  5: 2.488rem,
  6: 2.986rem,
  7: 3.583rem,
  8: 4.3rem,
  9: 5.16rem,
));


$font-size-lg: map_get($font-sizes, 1) !default;
$font-size-sm:  map_get($font-sizes, '-1') !default;

$font-weight-thin: 100 !default;
$font-weight-extra-light: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-black: 900 !default;

$font-size-base: map_get($font-sizes, 0) !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: map_get($font-sizes, 5) !default;
$h2-font-size: map_get($font-sizes, 4) !default;
$h3-font-size: map_get($font-sizes, 3) !default;
$h4-font-size: map_get($font-sizes, 2) !default;
$h5-font-size: map_get($font-sizes, 1) !default;
$h6-font-size: map_get($font-sizes, '-1') !default;

$headings-margin-bottom: ($spacer * 0.5) !default;
$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-extra-light !default;
$headings-line-height: $spacer * 2.5 !default;
$headings-color: $black !default;

$display1-size: map_get($font-sizes, 9) !default;
$display2-size: map_get($font-sizes, 8) !default;
$display3-size: map_get($font-sizes, 7) !default;
$display4-size: map_get($font-sizes, 6) !default;

$display1-weight: $font-weight-thin !default;
$display2-weight: $font-weight-thin !default;
$display3-weight: $font-weight-extra-light !default;
$display4-weight: $font-weight-extra-light !default;
// $display-line-height: $spacer * 4 !default;
$display-line-height: 1 !default;

$lead-font-size: map_get($font-sizes, 1) !default;
$lead-font-weight: $font-weight-light !default;

$small-font-size: 75% !default;

$text-muted: $gray-500 !default;

$blockquote-small-color: $gray-500 !default;
$blockquote-font-size: map_get($font-sizes, 1) !default;

$paragraph-margin-bottom: map_get($spacers, 3) !default;


//*-----------------------------------------------
//|   Cards
//-----------------------------------------------*/
$card-spacer-y: map_get($spacers, 3) !default;
$card-spacer-x: map_get($spacers, 3);



//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/

$body-bg: $white !default;
$body-color: $gray-800 !default;



//*-----------------------------------------------
//|   Buttons and Forms
//-----------------------------------------------*/

$input-padding-x:             0.625rem !default;
$input-btn-padding-y:         0.375rem !default;
$input-btn-padding-x:         2.188rem !default;
$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      1.5rem !default;
$input-btn-line-height-sm:     1.273 !default;
$input-btn-padding-y-lg:      0.9375rem !default;
$input-btn-padding-x-lg:      3.625rem !default;
$input-btn-line-height-lg:     0.7368 !default;


//*-----------------------------------------------
//|   Buttons
//-----------------------------------------------*/

$custom-select-padding-x:       $input-padding-x !default;
$custom-select-padding-y-lg:    $input-btn-padding-y !default;


//*-----------------------------------------------
//|   Buttons
//-----------------------------------------------*/

$btn-font-weight: $font-weight-medium !default;



//*-----------------------------------------------
//|   Badge
//-----------------------------------------------*/
$badge-font-weight: $font-weight-semi-bold !default;
$badge-padding-y: .6em !default;
$badge-padding-x: .8em !default;
$badge-pill-padding-x: .9em !default;


//*-----------------------------------------------
//|   Forms
//-----------------------------------------------*/
$input-disabled-bg: $gray-200 !default;
$input-group-addon-bg: $gray-200 !default;
$input-border-color: $gray-300 !default;
$input-color: $gray-900 !default;
$input-padding-x: $input-btn-padding-y !default;
$input-padding-x-sm: 0.5rem !default;
$input-padding-x-lg: $input-btn-padding-y-lg !default;



//*-----------------------------------------------
//|   Pagination
//-----------------------------------------------*/
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color: $gray-600 !default;
$pagination-border-color: $gray-300 !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $black !default;
$pagination-hover-border-color: $black !default;

$pagination-active-color: $white !default;
$pagination-active-bg: $black !default;
$pagination-active-border-color: $black !default;

$pagination-disabled-color: $gray-400;
$pagination-disabled-border-color: $gray-200;

//*-----------------------------------------------
//|   Tables
//-----------------------------------------------*/
$table-border-color: $gray-300 !default;
$table-head-bg: $gray-200 !default;
$table-head-color: $dark !default;
$table-dark-bg: $gray-1000 !default;
$table-dark-border-color: lighten($gray-1000, 7.5%) !default;
$table-striped-order: even !default;


//*-----------------------------------------------
//|   Navigation
//-----------------------------------------------*/
$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-hover-color: rgba($white, 0.8) !default;
$navbar-font-size: $font-size-base * 0.8 !default;

$navbar-light-color: rgba($black, .55) !default;
$navbar-light-hover-color: rgba($black, .9) !default;
$navbar-light-active-color: $black !default;
$navbar-light-disabled-color: rgba($black, .25) !default;

$navbar-dark-color: rgba($white, .55) !default;
$navbar-dark-hover-color: $black !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, .25) !default;
$navbar-padding-x: $spacer !default;
$navbar-brand-font-size: 1.3rem !default;
$navbar-brand-padding-y: 0.8125rem !default;
$navbar-dark-toggler-border-color: transparent !default;

//*-----------------------------------------------
//|   Nav Tab
//-----------------------------------------------*/

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;


//*-----------------------------------------------
//|   Dropdowns | Dropdown menu container and contents.
//-----------------------------------------------*/
$dropdown-bg: rgba(10,10,10,.92);
$dropdown-padding-y: 0 !default;
$dropdown-link-color: rgba(255,255,255,.5) !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $black !default;
$dropdown-divider-bg: $dropdown-link-color !default;
$dropdown-font-size: $navbar-font-size !default;
$dropdown-item-padding-y: 0.8rem !default;
$dropdown-item-padding-x: 1.2rem !default;
$dropdown-border-width: 0 !default;
$dropdown-link-active-color: $dropdown-link-hover-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

//*-----------------------------------------------
//|   Theme Custom Variable
//-----------------------------------------------*/
$dropdown-item-border-color: rgba(73,71,71,.15) !default;

//*-----------------------------------------------
//|   Breadcrumbs
//-----------------------------------------------*/
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: 'transparent';
$breadcrumb-divider: quote("\00BB") !default;
$breadcrumb-divider-color: $gray-400 !default;
$breadcrumb-active-color: $white !default;


//*-----------------------------------------------
//|   CODE
//-----------------------------------------------*/

$code-font-size: 0.875rem !default;

//*-----------------------------------------------
//|   Floating Label Group
//-----------------------------------------------*/

$floating-label-input-padding-x: $input-btn-padding-y;
$floating-label-input-padding-y: $input-btn-padding-y;
$floating-label-line-height: 1.4rem;

$floating-label-input-padding-x-lg: $input-btn-padding-y-lg;
$floating-label-input-padding-y-lg: $input-btn-padding-y-lg;
$floating-label-line-height-lg: 1rem;