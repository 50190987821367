/*-----------------------------------------------
|   Logos
-----------------------------------------------*/
.logo-reign {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.25rem 0 0 0.25rem;
  line-height: 1.875rem;
  text-indent: 0.4375rem;
  text-align: center;
  background-color: $white;
  color: $black;
  display: inline-block;
  font-size: 3.75rem;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-black;
  overflow: hidden;
}
.logo{
  height: $spacer * 2.5;
  width: auto;
}
.logo-grid{
  height: auto;
  width: $spacer * 7;
}
