/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;

  &.bg-right {
    left: auto;
    right: 0;
  }

  &.overlay:before {
    position: absolute;
    content: '';
    background: rgba($black, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.overlay-primary:before {
    background: rgba($primary, 0.4);
  }
  &.overlay-info:before {
    background: rgba($info, 0.4);
  }
  &.overlay-success:before {
    background: rgba($success, 0.4);
  }
  &.overlay-warning:before {
    background: rgba($warning, 0.4);
  }
  &.overlay-danger:before {
    background: rgba($danger, 0.4);
  }
  &.overlay-0:before {
    background: rgba($black, 0.7);
  }
  &.overlay-1:before {
    background: rgba($black, 0.55);
  }
  &.overlay-2:before {
    background: rgba($black, 0.4);
  }
  &.overlay-gradient:before {
    background: linear-gradient(-45deg, rgba(0, 25, 66, 0.702) 0, rgba(244,244,234,0.5) 100%);
  }
  .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }
  .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.overlay-z-index-1:before {
  z-index: 1;
}
.bg-fixed {
  background-attachment: fixed;
}

.has-parallax {
  position: relative;
  overflow: hidden;
}

.bg-glass{
  background-color: rgba($white, 0.9);
}
.header-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mb_YTPBar{
  display: none !important;
}
.ytp-chrome-top {
  display: none !important;
  visibility: hidden !important;
}
.ytp-title {
  display: none;
}
.hoverbox-background {
  background-color: rgba(0, 0, 0, 0.8);
}
/*-----------------------------------------------
|   Background Colors [Grays]
-----------------------------------------------*/
@each $key, $value in $grays {
  @include bg-variant('.bg-#{$key}', $value);
}
