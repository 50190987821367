.flex-direction-nav a.flex-prev,
.flex-direction-nav a.flex-next {
  color: $white;
  font-size: 1.5rem;
  &:before {
    display: none;
  }
}
.flex-direction-nav .indicator-arrow {
  height: 1.125rem;
  width: 1.125rem
}